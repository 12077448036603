<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<h3 class="h3 mb-0">帳號權限</h3>
			<button class="btn btn-info flex-shrink-0" @click="showEditModal()">新增帳號</button>
		</div>

		<div class="d-flex mb-4" style="width: 400px">
			<input
				type="text"
				class="form-control me-3"
				placeholder="帳號/姓名"
				v-model="searchKeyword"
				@keyup.enter="getList()"
			/>
			<button class="flex-shrink-0 btn btn-primary" @click="getList()">查詢</button>
		</div>

		<table class="table mb-5">
			<thead class="bg-light">
				<tr>
					<th>帳號</th>
					<th>名稱</th>
					<th>角色</th>
					<th>備註</th>
					<th style="width: 50px"></th>
					<th style="width: 50px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in pageList" :key="item.id">
					<td>{{ item.account }}</td>
					<td>{{ item.name }}</td>
					<td>{{ item.role_name }}</td>
					<td>{{ textEllipsis(item.note, 20) }}</td>
					<td>
						<a class="link-primary" role="button" @click="showEditModal(item.id)">編輯</a>
					</td>
					<td>
						<a v-if="item.role_id !== 1" class="link-danger" role="button" @click="deleteData(item.id)"
							>刪除</a
						>
					</td>
				</tr>
			</tbody>
		</table>

		<Pagination
			v-model="pagination.currentPage"
			:records="pagination.records"
			:per-page="pagination.perPage"
			:options="pagination.options"
			@paginate="getPageList"
		/>

		<div id="editModal" class="modal fade" tabindex="-1">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{ editID >= 0 ? "編輯" : "新增" }}帳號</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<Vueform
							ref="editForm$"
							:schema="editFormSchema"
							:display-errors="false"
							validate-on="step"
						></Vueform>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
						<button type="button" class="btn btn-primary" @click="editSubmit()">確定</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { apiUsers, apiGetRoleList } from "@/assets/js/api.js";
import { textEllipsis } from "@/assets/js/common.js";
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { Modal } from "bootstrap";

export default {
	name: "Users",
	setup() {
		const store = useStore();

		let dataList = [];
		const pageList = ref([]);
		const searchKeyword = ref("");

		const pagination = reactive({
			currentPage: 1,
			records: 0,
			perPage: 20,
			options: {
				theme: "bootstrap4",
				hideCount: true,
			},
		});

		let editModal = {};
		const editID = ref(-1); // -1: add ; >= 0: edit
		const editForm$ = ref(null);
		const editFormSchema = ref({
			account: {
				label: "帳號",
				type: "text",
				rules: ["required", "min:2", "max:20"],
				default: "",
			},
			password: {
				label: "密碼",
				type: "text",
				inputType: "password",
				default: "",
				rules: ["min:4", "max:20"],
			},
			password_confirmation: {
				label: "確認密碼",
				type: "text",
				inputType: "password",
				default: "",
				rules: ["min:4", "max:20"],
			},
			name: {
				label: "名稱",
				type: "text",
				rules: ["required", "min:2", "max:20"],
				default: "",
			},
			role_id: {
				label: "角色",
				type: "select",
				items: [],
				default: "",
				rules: ["required"],
				native: false,
				search: true,
				canClear: false,
				canDeselect: false,
			},
			note: {
				label: "備註",
				type: "textarea",
				default: "",
				rules: ["min:2", "max:80"],
				autogrow: false,
			},
		});

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		const getRoleList = () => {
			setPageLoading(true);

			apiGetRoleList()
				.then((response) => {
					setPageLoading(false);
					if (response.data.status == "0") {
						editFormSchema.value.role_id.items = response.data.data.role_list;
					} else {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						});
					}
				})
				.catch((error) => {
					console.error(error);
					Swal.fire({
						icon: "error",
						text: "CODE ERROR",
					});
					setPageLoading(false);
				});
		};

		const getList = () => {
			setPageLoading(true);

			apiUsers({
				method: "get",
				data: {
					text: searchKeyword.value,
				},
			})
				.then((response) => {
					dataList = response.data.data;
					pagination.currentPage = 1;
					pagination.records = response.data.data.length;
					getPageList(1);

					setPageLoading(false);
				})
				.catch(() => {
					setPageLoading(false);
				});
		};

		const getPageList = (currentPage) => {
			let startIndex = (currentPage - 1) * pagination.perPage;
			let endIndex = startIndex + pagination.perPage;

			pageList.value = dataList.slice(startIndex, endIndex);
		};

		const getDetail = (id) => {
			return new Promise((resolve, reject) => {
				setPageLoading(true);

				apiUsers({
					method: "get",
					id,
				})
					.then((response) => {
						setPageLoading(false);

						if (response.data.status == "0") {
							resolve(response.data.data.user);
						} else {
							reject();
						}
					})
					.catch(() => {
						setPageLoading(false);
					});
			});
		};

		const showEditModal = async (id) => {
			editID.value = -1;
			editForm$.value.reset();

			// edit
			if (id) {
				let detailData = await getDetail(id);
				editID.value = detailData.id;
				editForm$.value.load(detailData);
			}

			setTimeout(() => {
				editForm$.value.resetValidators();
				editModal.show();
			}, 100);
		};

		const editSubmit = () => {
			const data = editForm$.value.data;
			editForm$.value.validate().then(() => {
				if (!editForm$.value.invalid) {
					setPageLoading(true);

					let apiOptions = {
						method: "",
						data,
					};

					if (editID.value < 0) {
						//add
						apiOptions.method = "post";
					} else {
						//edit
						apiOptions.method = "put";
						apiOptions.id = editID.value;
					}

					apiUsers(apiOptions)
						.then((response) => {
							setPageLoading(false);

							if (response.data.status == "0") {
								Swal.fire({
									icon: "success",
									text: response.data.message,
								});
								getList();
								editModal.hide();
							} else {
								Swal.fire({
									icon: "warning",
									text: response.data.message,
								});
							}
						})
						.catch(() => {
							setPageLoading(false);
						});
				}
			});
		};

		const deleteData = (id) => {
			Swal.fire({
				icon: "question",
				text: "確定要刪除嗎?",
				showCancelButton: true,
				confirmButtonText: "確定",
				cancelButtonText: "取消",
			}).then((result) => {
				if (result.isConfirmed) {
					setPageLoading(true);

					apiUsers({
						method: "delete",
						id,
					})
						.then((response) => {
							setPageLoading(false);

							if (response.data.status == "0") {
								Swal.fire({
									icon: "success",
									text: response.data.message,
								});
								getList();
							} else {
								Swal.fire({
									icon: "warning",
									text: response.data.message,
								});
							}
						})
						.catch(() => {
							setPageLoading(false);
						});
				}
			});
		};

		onMounted(() => {
			editModal = new Modal(document.getElementById("editModal"));
			getList();
			getRoleList();
		});

		return {
			searchKeyword,
			pageList,
			pagination,
			editForm$,
			editID,
			editFormSchema,
			getList,
			getPageList,
			showEditModal,
			editSubmit,
			deleteData,
			textEllipsis,
		};
	},
};
</script>

<style lang="scss" scoped></style>
